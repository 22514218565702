<template>
	<data-table :value="items">
		<data-column field="created_at" header="Data zdarzenia" sortable headerStyle="width: 160px"  />
		<data-column field="log.name" header="Działanie" sortable />
		<data-column field="user" header="Uzytkownik" headerStyle="width: 200px" sortable>
			<template #body="slotProps">
				<div>{{ slotProps.data.user.name }}</div>
				<div v-if="slotProps.data.user.name == ''">{{ slotProps.data.user.email }}</div>
			</template>
		</data-column>
		<data-column field="ip" header="IP" sortable headerStyle="width: 100px"  />
		<data-column field="browser.name" header="Przeglądarka" headerStyle="width: 145px" sortable>
			<template #body="slotProps">
				<div v-if="slotProps.data.browser && slotProps.data.browser.name">{{ slotProps.data.browser.name }}</div>
			</template>
		</data-column>
		<data-column field="browser.model" header="Platforma" headerStyle="width: 150px" sortable>
			<template #body="slotProps">
				<div v-if="slotProps.data.browser">{{ slotProps.data.browser.platformName }}</div>
			</template>
		</data-column>
	</data-table>
</template>

<script setup>
defineProps({
	items: Array,
});
</script>

<script>
export default {
	name: 'ActivityLogIndex',
};
</script>
