<template>
	<form ref="form" @submit.prevent="onHandleSubmiForm">
		<slot></slot>

		<div class="d-flex justify-content-between">
			<div class="fs-14 lh-24 text-muted" :class="{'pl-25px' : form && form.history}">
				<div v-if="form && form.history && form.history.created && form.history.created.date">Utworzono
					<span class="text-black">{{ form.history.created.date }}</span> przez
					<span class="text-black" v-if="form.history.created.user.name == ''">{{ form.history.created.user.email }}</span>
					<span class="text-black" v-else>{{ form.history.created.user.name }}</span>
				</div>
				<div v-if="form && form.history && form.history.updated && form.history.updated.date">Aktualizowano
					<span class="text-black">{{ form.history.updated.date }}</span> przez
					<span class="text-black" v-if="form.history.updated.user.name == ''">{{ form.history.updated.user.email }}</span>
					<span class="text-black" v-else>{{ form.history.updated.user.name }}</span>
					<span @click="onLoadHistory(form.history.model, form.history.hash)" @keydown="onLoadHistory(form.history.model, form.history.hash)"
						class="ms-1 text-primary cursor-pointer text-decoration-underline">(historia wpisu)</span>
				</div>
			</div>
			<template v-if="hasSlot('button')">
				<slot name="button"></slot>
			</template>
			<div v-else :class="classAction">
				<ui-button class="p-button-primary p-button-outlined me-2" v-if="routeIndex && back" :to="{name: routeIndex}" label="Wróć do listy"></ui-button>
				<ui-button class="p-button-danger me-2" v-if="form && form.hash && remove" @click="onHandleDeleteItem" label="Usuń pozycję"></ui-button>
				<ui-button v-if="!split" class="p-button-success" type="submit" :label="button"></ui-button>
				<split-button v-else class="p-button-success" id="action-button" type="submit" :label="actionLabel" :model="actionItems" />
			</div>
		</div>

		<template v-if="hasSlot('footer')">
			<slot name="footer"></slot>
		</template>

		<Dialog header="Historia wpisu" v-model:visible="dialog" :modal="true" :draggable="false" :breakpoints="{'1300px': '90vw'}" :style="{width: '1300px'}">
			<ui-card>
				<template #body>
					<table-activity-log :items="itemsActivityLog" />
				</template>
			</ui-card>
			<template #footer>
				<ui-button @click="dialog = false" label="Zamknij okno" />
			</template>
		</Dialog>
	</form>
</template>

<script>
import axios from 'axios';
import Dialog from 'primevue/dialog';
import SplitButton from 'primevue/splitbutton';
import { useForm } from '@/composables/useForm';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import TableActivityLog from '@/components/Table/ActivityLog/Index.vue';

export default {
	name: 'FormBody',
	props: {
		button: {
			type: String,
			default: 'Zapisz',
		},
		async: {
			type: Boolean,
			default: false,
		},
		method: {
			type: String,
			default: 'post',
		},
		back: {
			type: Boolean,
			default: true,
		},
		remove: {
			type: Boolean,
			default: true,
		},
		hash: String,
		action: String,
		form: Object,
		error: Object,
		callback: Function,
		addCallback: Function,
		errorMessage: String,
		successMessage: String,
		fields: Object,
		split: {
			type: Boolean,
			default: false,
		},
		classAction: {
			type: String,
			default: 'd-flex mt-4 justify-content-end',
		},
	},
	components: {
		Dialog,
		TableActivityLog,
		SplitButton,
	},
	mounted() {
		// console.log(this.$refs);
	},
	emits: ['error', 'form'],
	setup(props, { emit, slots }) {
		const toast = useToast();
		const confirm = useConfirm();
		const route = useRoute();
		const router = useRouter();
		const dialog = ref(false);
		const itemsActivityLog = ref({});
		const { actionValue, onSetAction } = useForm();

		const hasSlot = (name) => !!slots[name];

		const routeIndex = computed(() => {
			let routeBack = route.name;
			routeBack = routeBack.replace('Update', 'Index');
			routeBack = routeBack.replace('Create', 'Index');

			return routeBack;
		});

		const onLoadHistory = async (model, id) => {
			dialog.value = true;
			try {
				const request = await axios.post('user/activity-log', { model, id });
				const { data } = request;
				itemsActivityLog.value = data;
			} catch (e) {
				console.error(e);
			}
		};

		const onHandleDeleteItem = async () => {
			confirm.require({
				message: 'Czy napewno chcesz usunąć tą pozycję? Pamiętaj, że ta czynność jest nieodwracalna.',
				header: 'Potwierdź usunięcie',
				icon: 'pi pi-info-circle',
				acceptClass: 'p-button-danger',
				acceptLabel: 'Tak, usuń',
				rejectLabel: 'Nie, pozostaw',
				accept: async () => {
					await axios.delete(`${props.action}/${props.form.hash}`);
					const name = route.name.replace('Update', 'Index');
					router.push({ name });
					toast.add({
						severity: 'success', summary: 'Sukces', detail: 'Pozycja została usunięta', life: 3000,
					});
				},
			});
		};

		const onHandleSubmiForm = async () => {
			let type = props.method;
			let { action } = props;
			if (props.hash) {
				type = 'put';
				action = `${action}/${props.hash}`;
			}

			try {
				emit('update:error', []);
				const request = await axios[type](action, props.form);
				const { data } = request;

				if (props.callback !== undefined) {
					if (props.async) {
						await props.callback(data);
					} else {
						props.callback(data);
					}
				} else if (data) {
					let name = '';
					const actionName = actionValue.value.charAt(0).toUpperCase() + actionValue.value.slice(1);
					name = route.name;
					name = name.replace('Create', actionName);
					name = name.replace('Update', actionName);

					if (actionName === 'Update') {
						if (data.hash) {
							router.push({ name, params: { hash: data.hash } });
						}
					} else {
						router.push({ name });
					}

					if (actionName === 'Create') {
						document.location.reload();
					} else if (actionName !== 'Create') {
						emit('update:form', data);
					}
					if (props.addCallback !== undefined) {
						await props.addCallback(data);
					}
					toast.add({
						severity: 'success', summary: 'Sukces', detail: type === 'put' ? 'Pozycja została zaktualizowana' : 'Pozycja została dodana', life: 3000,
					});
				}
			} catch (err) {
				const { response } = err; console.error(err);
				const { status, data } = response;
				if (status === 422) {
					emit('update:error', data.errors);
					toast.add({
						severity: 'error', summary: 'Uwaga!', detail: 'Popraw oznaczone pola w formularzy, aby zapisać pozycję', life: 3000,
					});
				} else if (status) {
					toast.add({
						severity: 'error', summary: 'Uwaga!', detail: props.errorMessage || `Wystąpił błąd. Spróbuj ponownie (Status błędu ${status})`, life: 3000,
					});
				}
			}
		};

		const actionItems = ref([
			{ id: 'create', label: `${props.button} i dodaj nową pozycję`, command: () => { onSetAction('create'); } },
			{ id: 'update', label: `${props.button} i przejdź do edycji`, command: () => { onSetAction('update'); } },
			{ id: 'index', label: `${props.button} i wróć do listy`, command: () => { onSetAction('index'); } },
		]);

		const actionLabel = computed(() => {
			const action = actionItems.value.find((e) => e.id === actionValue.value);
			if (action) {
				return action.label;
			}
			return 'Zapisz';
		});

		return {
			actionValue,
			dialog,
			hasSlot,
			routeIndex,
			itemsActivityLog,
			actionItems,
			actionLabel,
			onLoadHistory,
			onHandleSubmiForm,
			onHandleDeleteItem,
		};
	},
};
</script>
