<template>
	<div class="position-relative form__field__checked">
		<div class="d-flex">
			<input-checkbox v-bind="$attrs" />
			<div class="ms-3">
				<div v-if="label" v-html="label"></div>
				<slot v-else />
			</div>
		</div>
		<input-error :errors="error" />
	</div>
</template>

<script setup>
import InputCheckbox from 'primevue/checkbox';
import InputError from './Error.vue';

defineProps({
	label: String,
	error: Object,
});
</script>

<script>
export default {
	name: 'InputCheckbox',
};
</script>
