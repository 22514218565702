import axios from 'axios';
import { defineStore } from 'pinia';

export const useConsentStore = defineStore('store.consent', {
	state: () => ({
		items: [],
	}),
	actions: {
		async getConsent() {
			if (this.items.length === 0) {
				try {
					const response = await axios.get('consent');
					const { data } = response;
					this.items = data;
				} catch (e) {
					console.error(e);
				}
			}
			return this.items;
		},
	},
});
