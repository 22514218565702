<template>
	<div v-if="count > 1 || always">
		<ui-headline v-if="headline">Placówki i sale</ui-headline>
		<span v-else>Placówki i sale</span>

		<ul v-if="error" class="form__error inline mb-2"><li>Zaznacz placówkę.</li></ul>
		<div class="mt-2" v-for="item in items" :key="item.id">
			<input-checkbox :label="item.name" v-model="model" :value="item.hash" />
		</div>
	</div>
</template>

<script setup>
import { useFacility } from '@/composables/useFacility';
import {
	computed,
	onMounted,
	ref,
	watch,
} from 'vue';

const { items, onLoadItems } = useFacility();
const count = computed(() => Object.keys(items.value).length);

const model = ref([]);

const props = defineProps({
	headline: {
		type: Boolean,
		default: false,
	},
	always: {
		type: Boolean,
		default: false,
	},
	modelValue: Array,
	error: Array,
});
const emit = defineEmits(['modelValue']);

watch(() => props.modelValue, () => {
	model.value = props.modelValue;
}, { deep: true });

watch(() => model.value, () => {
	emit('update:modelValue', model.value);
}, { deep: true });

onMounted(() => {
	onLoadItems();

	model.value = props.modelValue;
});
</script>

<script>
export default {
	name: 'InputFacility',
};
</script>
