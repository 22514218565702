import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useItems() {
	const route = useRoute();
	const router = useRouter();
	const hash = ref();

	onMounted(() => {
		hash.value = route.params.hash;
	});

	const onLoadItem = async (url: string, redirect: string) => {
		try {
			const request = await axios.get(`${url}/${hash.value}`);
			const { data } = request;
			return data;
		} catch (e: any) {
			const { status } = e.response;
			if (status === 404) {
				router.push({ name: redirect });
			}
			return '';
		}
	};

	return {
		hash,
		onLoadItem,
	};
}
