import { defineStore } from 'pinia';
import axios from 'axios';

export const useUserStore = defineStore('store.user', {
	state: () => ({
		authenticated: false,
		user: {},
		token: '',
		idSchool: 0,
		idUserRole: 0,
		module: [],
		workspaces: [],
		semestr: [],
		currentSemestr: '',
		dialogWorkspace: false,
		subscription: {},
	}),
	getters: {
		userName(state: any) {
			if (state.user.firstname || state.user.lastname) {
				return [state.user.firstname, state.user.lastname].join(' ').trim();
			}
			return state.user.email;
		},
		hashRole(state: any) {
			return state.workspace.hash_user_role;
		},
		workspace(state: any) {
			let idSchool = '';
			let school = '';
			let idSchoolType = '';
			let subscription = '';
			let idUserRole = '';
			let userRole = '';
			let hashSchool = '';
			let hashUserRole = '';

			if (state.idSchool && state.workspaces.workspaces && state.workspaces.workspaces[state.idSchool] !== undefined) {
				const itemSchool = state.workspaces.workspaces[state.idSchool];
				idSchool = itemSchool.id;
				idSchoolType = itemSchool.id_school_type;
				school = itemSchool.name;
				hashSchool = itemSchool.hash;
				subscription = itemSchool.subscription;
			}

			if (state.idUserRole && state.workspaces.workspaces && state.workspaces.workspaces[state.idSchool].roles[state.idUserRole] !== undefined) {
				const itemUserRole = state.workspaces.workspaces[state.idSchool].roles[state.idUserRole];
				idUserRole = itemUserRole.id;
				userRole = itemUserRole.name;
				hashUserRole = itemUserRole.hash;
			}

			return {
				id_school: idSchool,
				id_school_type: idSchoolType,
				school,
				subscription,
				id_user_role: idUserRole,
				user_role: userRole,
				hash_school: hashSchool,
				hash_user_role: hashUserRole,
			};
		},
		semestrData(state: any) {
			return state.semestr.find((e: any) => e.hash === state.currentSemestr);
		},
	},
	actions: {
		setToken(token: any) {
			this.token = token;
		},
		setUser(user: object) {
			this.user = user;
		},
		setAuthenticated(value: boolean) {
			this.authenticated = value;
		},
		setIdSchool(idSchool: number) {
			this.idSchool = idSchool;
		},
		setIdUserRole(idUserRole: number) {
			this.idUserRole = idUserRole;
		},
		setWorkspaces(workspaces: any) {
			this.workspaces = workspaces;
		},
		setDialogWorkspace(status: boolean) {
			this.dialogWorkspace = status;
		},
		setModule(module: any) {
			this.module = module;
		},
		setSemestr(semestr: any) {
			this.semestr = semestr;
		},
		setCurrentSemestr(semestr: any) {
			this.currentSemestr = semestr;
			axios.defaults.headers.common['Hash-Semestr'] = semestr;
			axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
			axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
			axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
			axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type,Authorization';
			axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,OPTIONS';
		},
	},
});
