<template>
	<input-field>
		<auto-complete v-bind="$attrs" :suggestions="filtered" @complete="search($event)" field="name">
			<template #item="slotProps">
                <slot name="item" :slotProps="slotProps"></slot>
            </template>
		</auto-complete>
	</input-field>
</template>

<script setup>
import axios from 'axios';
import { ref } from 'vue';
import InputField from '@/components/Input/Field.vue';
import AutoComplete from 'primevue/autocomplete';

const props = defineProps({
	src: String,
});

const filtered = ref();

const search = async (e) => {
	const { query } = e;
	const request = await axios.post(props.src, { query });
	filtered.value = request.data;
};
</script>

<script>
export default {
	name: 'InputAutocomplete',
};
</script>
