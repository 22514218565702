<template>
	<input-field>
		<input-calendar v-bind="$attrs" v-model="date" @date-select="onDateSelect" />
	</input-field>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import moment from 'moment';
import InputCalendar from 'primevue/calendar';
import InputField from './Field.vue';

const props = defineProps({
	modelValue: String,
});
const emit = defineEmits(['modelValue']);
const date = ref();

const onDateSelect = (value) => {
	const date = moment(value).format('YYYY-MM-DD');
	emit('update:modelValue', date);
};

onMounted(() => {
	date.value = props.modelValue;
});
watch(() => props.modelValue, () => {
	date.value = props.modelValue;
});
</script>

<script>
export default {
	name: 'InputCalendar',
};
</script>
