import { defineStore } from 'pinia';
import axios from 'axios';
import moment from 'moment';

const itemsDefault: object[] = [];
const itemsMessage: object[] = [];
const itemsTodo: object[] = [];

export const useNotificationStore = defineStore('store.notification', {
	state: () => ({
		default: {
			items: itemsDefault,
			all: 0,
		},
		message: {
			items: itemsMessage,
			all: 0,
		},
		todo: {
			items: itemsTodo,
			all: 0,
		},
	}),
	actions: {
		setDefaultNotification(item: object) {
			this.default.items.push(item);
			this.default.all += 1;
		},
		setTodoNotification(item: object) {
			this.message.items.push(item);
			this.message.all += 1;
		},
		setMessageNotification(item: object) {
			this.todo.items.push(item);
			this.todo.all += 1;
		},
		async openedDefaultNotification(index: number) {
			const item = JSON.parse(JSON.stringify(this.default.items[index]));
			await axios.put(`/notification/opened/${item.hash}`);
			item.opened_at = moment().format('DD.MM.YYYY HH:mm');
			this.default.all -= 1;
			this.default.items[index] = item;
		},
		async openedMessageNotification(index: number) {
			const item = JSON.parse(JSON.stringify(this.message.items[index]));
			await axios.put(`/notification/opened/${item.hash}`);
			item.opened_at = moment().format('DD.MM.YYYY HH:mm');
			this.message.all -= 1;
			this.message.items[index] = item;
		},
		async openedTodoNotification(index: number) {
			const item = JSON.parse(JSON.stringify(this.todo.items[index]));
			await axios.put(`/notification/opened/${item.hash}`);
			item.opened_at = moment().format('DD.MM.YYYY HH:mm');
			this.todo.all -= 1;
			this.todo.items[index] = item;
		},
		async getNotification() {
			try {
				this.default = { items: [], all: 0 };
				this.message = { items: [], all: 0 };
				this.todo = { items: [], all: 0 };

				const request = await axios.get('notification');
				const notifications = request.data;

				Object.keys(notifications).forEach((n) => {
					Object.keys(notifications[n]).forEach((i) => {
						if (n === 'default') {
							this.setDefaultNotification(notifications[n][i]);
						} else if (n === 'message') {
							this.setMessageNotification(notifications[n][i]);
						} else if (n === 'todo') {
							this.setTodoNotification(notifications[n][i]);
						}
					});
				});
			} catch (e) {
				console.error(e);
			}
		},
	},
});

/* {
					type: 'system',
					icon: 'campaign',
					date: '2022-06-12 09:00',
					title: 'Twoje konto zostało w pełni aktywowane. Mozesz korzystać z pełnej funckjonalności systemu!',
					hash: '123abc',
					className: 'info',
				}, */
