<template>
	<router-view />
	<Toast position="bottom-center" />
	<Toast group="notify" class="p-toast-notify" :closable="false">
		<template #message="slotProps">
            <div class="flex flex-column">
                <h4>{{slotProps.message.summary}}</h4>
                <p>{{slotProps.message.detail}}</p>
                <div class="row">
                    <div class="col-6">
                        <button class="w-100">{{ slotProps.message.left }}</button>
                    </div>
                    <div class="col-6">
                        <button class="w-100">{{ slotProps.message.right }}</button>
                    </div>
                </div>
            </div>
        </template>
	</Toast>
</template>

<script setup>
import { onMounted } from 'vue';
import { useConsentStore } from '@/store/useConsentStore';
import Toast from 'primevue/toast';

const store = useConsentStore();

onMounted(async () => {
	await store.getConsent();
});
</script>
