<template>
	<div class="d-flex align-items-center">
		<prime-input-switch v-bind="$attrs" v-model="model" />
		<span class="ms-2">{{ label }}</span>
	</div>
</template>

<script setup type="ts">
import { ref, watch, onMounted } from 'vue';
import PrimeInputSwitch from 'primevue/inputswitch';

const props = defineProps({
	modelValue: [Boolean, String],
	label: String,
});

const model = ref(false);

onMounted(() => {
	model.value = props.modelValue;
});

watch(() => props.modelValue, () => {
	model.value = props.modelValue;
});

</script>

<script>
export default {
	name: 'InputSwitch',
};
</script>
