<template>
	<PrimeButton v-bind="$attrs" class="btn" @click="goTo" />
</template>

<script setup>
import PrimeButton from 'primevue/button';
import { useRouter } from 'vue-router';

const props = defineProps({
	to: [Object, String],
});

const router = useRouter();

const goTo = () => {
	if (props.to !== undefined && props.to !== '') {
		router.push(props.to);
	}
};
</script>

<script>
export default {
	name: 'UiButton',
};
</script>
