import { createApp } from 'vue';
import './registerServiceWorker';
import hljs from 'highlight.js';
import axios from 'axios';
import VueApexCharts from 'vue3-apexcharts';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import DataTable from 'primevue/datatable';
import DataColumn from 'primevue/column';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import InlineSvg from 'vue-inline-svg';
import ConfirmationService from 'primevue/confirmationservice';
import App from './App.vue';
import router from './router';

import PageLayout from './layouts/PageLayout.vue';

import FormBody from './components/Form/Body.vue';
import InputAutocomplete from './components/Input/Autocomplete.vue';
import InputText from './components/Input/Text.vue';
import InputDropdown from './components/Input/Dropdown.vue';
import InputDropdownMulti from './components/Input/DropdownMulti.vue';
import InputNumber from './components/Input/Number.vue';
import InputCalendar from './components/Input/Calendar.vue';
import InputPassword from './components/Input/Password.vue';
import InputCheckbox from './components/Input/Checkbox.vue';
import InputRadio from './components/Input/Radio.vue';
import InputSwitch from './components/Input/Switch.vue';
import InputTextarea from './components/Input/Textarea.vue';
import InputFacility from './components/Input/Facility.vue';
import InputColor from './components/Input/Color.vue';
import InputMask from './components/Input/Mask.vue';
import TableAction from './components/Action/Index.vue';

import UiButton from './components/Ui/Button/Index.vue';
import UiCard from './components/Ui/Card/Index.vue';
import UiHeadline from './components/Ui/Headline/Index.vue';

import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import 'highlight.js/styles/monokai-sublime.css';
import './assets/scss/app.scss';
import './assets/scss/dark.scss';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';

axios.defaults.baseURL = 'https://api.eskul.cloud/'; // '/api/';
if (localStorage.getItem('auth._token') !== undefined && localStorage.getItem('auth._token') !== null) {
	axios.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('auth._token')}` };
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type,Authorization';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,OPTIONS';

let activity = 'ending';

axios.interceptors.request.use((config) => {
	document.body.classList.add('loading-indicator');
	activity = 'loading';
	return config;
}, (error) => {
	document.body.classList.remove('loading-indicator');
	return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
	activity = 'waiting';
	setTimeout(() => {
		if (activity === 'waiting') {
			activity = 'ending';
			document.body.classList.remove('loading-indicator');
		}
	}, 300);
	return response;
}, (error) => {
	console.log(error);
	document.body.classList.remove('loading-indicator');
	return Promise.reject(error);
});

/* declare const window: any;

window.Pusher = Pusher;

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: 'local',
	wsHost: 'app.eskul.cloud',
	wsPort: 6001,
	forceTLS: false,
	disableStats: true,
}); */

const app = createApp(App);
app.directive('highlight', (el) => {
	const blocks = el.querySelectorAll('pre code');
	blocks.forEach((block: any) => {
		hljs.highlightBlock(block);
	});
});
app.use(createPinia());
app.use(router);
app.use(PrimeVue, {
	locale: {
		startsWith: 'Starts with',
		contains: 'Contains',
		notContains: 'Not contains',
		endsWith: 'Ends with',
		equals: 'Equals',
		notEquals: 'Not equals',
		noFilter: 'No Filter',
		lt: 'Less than',
		lte: 'Less than or equal to',
		gt: 'Greater than',
		gte: 'Greater than or equal to',
		dateIs: 'Date is',
		dateIsNot: 'Date is not',
		dateBefore: 'Date is before',
		dateAfter: 'Date is after',
		clear: 'Clear',
		apply: 'Apply',
		matchAll: 'Match All',
		matchAny: 'Match Any',
		addRule: 'Add Rule',
		removeRule: 'Remove Rule',
		accept: 'Yes',
		reject: 'No',
		choose: 'Choose',
		upload: 'Upload',
		cancel: 'Cancel',
		dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
		dayNamesShort: ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'],
		dayNamesMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
		monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
		monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
		today: 'Today',
		weekHeader: 'Wk',
		firstDayOfWeek: 1,
		dateFormat: 'yy-mm-dd',
		weak: 'Weak',
		medium: 'Medium',
		strong: 'Strong',
		passwordPrompt: 'Enter a password',
		emptyFilterMessage: 'No results found',
		emptyMessage: 'No available options',
	},
});
app.use(ToastService);
app.use(PerfectScrollbar);
app.use(ConfirmationService);
app.use(DialogService);
app.use(VueApexCharts);
app.component('InlineSvg', InlineSvg);
app.component('PageLayout', PageLayout);
app.component('InputText', InputText);
app.component('InputDropdown', InputDropdown);
app.component('InputDropdownMulti', InputDropdownMulti);
app.component('InputNumber', InputNumber);
app.component('InputCalendar', InputCalendar);
app.component('InputPassword', InputPassword);
app.component('InputCheckbox', InputCheckbox);
app.component('InputRadio', InputRadio);
app.component('InputSwitch', InputSwitch);
app.component('InputTextarea', InputTextarea);
app.component('InputAutocomplete', InputAutocomplete);
app.component('InputFacility', InputFacility);
app.component('InputColor', InputColor);
app.component('InputMask', InputMask);
app.component('FormBody', FormBody);
app.component('UiCard', UiCard);
app.component('UiButton', UiButton);
app.component('UiHeadline', UiHeadline);
app.component('DataTable', DataTable);
app.component('DataColumn', DataColumn);
app.component('TableAction', TableAction);

app.mount('#app');
