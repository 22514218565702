<template>
	<div>
		<div class="d-flex mb-2">
			<prime-radio-button v-bind="$attrs" />
			<div class="ms-3">
				<div>{{ label }}</div>
				<input-error :errors="error" />
			</div>
		</div>
	</div>
</template>

<script setup>
import PrimeRadioButton from 'primevue/radiobutton';

defineProps({
	label: String,
});
</script>

<script>
export default {
	name: 'InputRadio',
};
</script>
