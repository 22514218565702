<template>
	<div class="card">
		<template v-if="hasSlot('before')">
			<slot name="before" />
		</template>
		<div class="card-body" v-if="hasSlot('title') || hasSlot('body') || title">
			<div v-if="hasSlot('title') || title || hasSlot('subtitle') || subtitle || hasSlot('buttons')" class="w-100 mb-3">
				<div class="d-flex justify-content-between">
					<div style="flex:1">
						<h5 v-if="hasSlot('title') || title" class="card-title">
							<slot v-if="hasSlot('title')" name="title" />
							<template v-else>{{ title }}</template>
						</h5>
						<h6 v-if="hasSlot('subtitle') || subtitle" class="card-subtitle font-weight-normal text-muted">
							<slot v-if="hasSlot('subtitle')" name="subtitle" />
							<template v-else>{{ subtitle }}</template>
						</h6>
					</div>
					<div>
						<slot v-if="hasSlot('buttons')" name="buttons" />
					</div>
				</div>
			</div>
			<slot v-if="hasSlot('body')" name="body" />
		</div>
		<template v-if="hasSlot('after')">
			<slot name="after" />
		</template>
	</div>
</template>

<script>
export default {
	name: 'UiCard',
	props: {
		title: String,
		subtitle: String,
	},
	setup(props, { slots }) {
		const hasSlot = (name) => !!slots[name];

		return { hasSlot };
	},
};
</script>
