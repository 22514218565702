<template>
	<div class="ui__headline">
		<h4><slot /></h4>
	</div>
</template>

<script>
export default {
	name: 'UiHeadline',
};
</script>
