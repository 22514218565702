import axios from 'axios';
import { useConfirm } from 'primevue/useconfirm';
import { onMounted, ref } from 'vue';
import { useItems } from './useItems';

const items = ref(<any[]>([]));
const form = ref({
	name: '',
	primary: false,
	address: {
		street: '',
		street_no: '',
		local_no: '',
		zip_code: '',
		town: '',
	},
	contact: {
		email: '',
		phone: '',
	},
	classroom: <any[]>[],
});

const classroom = ref({
	name: '',
	capacity: 1,
});

export function useFacility() {
	const error = ref({});
	const confirm = useConfirm();
	const { onLoadItem } = useItems();

	onMounted(() => {
		form.value = {
			name: '',
			primary: false,
			address: {
				street: '',
				street_no: '',
				local_no: '',
				zip_code: '',
				town: '',
			},
			contact: {
				email: '',
				phone: '',
			},
			classroom: <any[]>[],
		};

		classroom.value = {
			name: '',
			capacity: 1,
		};
	});

	const onLoadItems = async () => {
		try {
			const request = await axios.get('setting/facility');
			items.value = request.data;
		} catch (e) {
			console.error(e);
		}
	};

	const removeClassroom = (index: number) => {
		confirm.require({
			message: 'Czy napewno chcesz usunąć salę z placówki?',
			header: 'Potwierdź usunięcie',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Tak, usuń',
			rejectLabel: 'Nie, pozostaw',
			accept: async () => {
				form.value.classroom.splice(index, 1);
			},
		});
	};

	const onChangePrimary = async (status: any, hash: string) => {
		try {
			const request = await axios.put(`setting/facility/primary/${hash}`, { status });
			const { data } = request;
			items.value = data;
		} catch (e) {
			console.error(e);
		}
	};

	const onLoadForm = async () => {
		const data = await onLoadItem('setting/facility', 'facilityIndex');
		if (data) { form.value = data; }
	};

	const onAddClassroom = () => {
		form.value.classroom.push(classroom.value);
		classroom.value = { name: '', capacity: 1 };
	};

	const selected = ref([]);

	return {
		classroom,
		onAddClassroom,
		removeClassroom,
		onLoadItems,
		onLoadForm,
		onChangePrimary,
		items,
		selected,
		form,
		error,
	};
}
